import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
export const pageTitle = "Armeria Newsletter vol. 5";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "armeria-newsletter-vol-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-5",
        "aria-label": "armeria newsletter vol 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 5`}</h1>
    <p {...{
      "className": "date"
    }}>{`26th April 2023`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#announcements"
        }}>{`Announcements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-devs"
        }}>{`From the devs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-community"
        }}>{`From the community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#we-need-your-comments"
        }}>{`We need your comments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#good-first-issues"
        }}>{`Good first issues`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stay-tuned"
        }}>{`Stay tuned`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thank-you"
        }}>{`Thank you!`}</a></li>
    </ul>
    <video {...{
      "className": "hideOnReducedMotion",
      "src": "/bf04f0ae6df78db5375887fb6f8e2f78/armeria.m4v",
      "preload": "none",
      "autoPlay": true,
      "muted": true,
      "loop": true,
      "style": {
        "width": "282px",
        "height": "112px"
      }
    }}>{`
  `}<img parentName="video" {...{
        "src": "/2cbd433cd318026a1c3017f022dbc263/armeria.gif",
        "loading": "lazy",
        "width": 282,
        "height": 112
      }}></img>
    </video>
    <h2 {...{
      "id": "announcements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#announcements",
        "aria-label": "announcements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Announcements`}</h2>
    <div className="noBullet">
      <p>{`We have some announcements to share with you.`}</p>
      <ul>
        <li parentName="ul">{`📢 `}<strong parentName="li">{`Discontinuation of virtual office hours`}</strong>{`:`}
          <ul parentName="li">
            <li parentName="ul">{`We will transform the existing virtual hours to another form such as quarterly webinar. But we haven't decided how often we hold the webinar or what to cover. Please stay tuned.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`🙌 `}<strong parentName="li"><a parentName="strong" {...{
              "href": "https://github.com/line/armeria/discussions/4626"
            }}>{`Armeria adopters page`}</a>{` and limited goods package`}</strong>{`:`}
          <ul parentName="li">
            <li parentName="ul">{`You can now show off how well your service takes advantage of Armeria!`}</li>
            <li parentName="ul">{`Don't forget to request the limited goods packages.`}</li>
          </ul>
        </li>
      </ul>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAFjFsjSMUwf/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECEQMSIRP/2gAIAQEAAQUC7I8Vq6sfR4oM/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/ARLb/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIRICExof/aAAgBAQAGPwKGXpmmj//EABoQAQACAwEAAAAAAAAAAAAAAAEAERAhQaH/2gAIAQEAAT8hQaNQbwb4yhFx2VzArfif/9oADAMBAAIAAwAAABBEz//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBFsv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCBh//EABwQAQADAAMBAQAAAAAAAAAAAAEAESExQWFxkf/aAAgBAQABPxBz6fVP5DMdKg3R1KACCmw6L1oNX9mRqeHcsEi88J//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "limited package",
            "title": "limited package",
            "src": "/static/4458ff7c995b9fe5167804dd1415684a/6aca1/limited_package.jpg",
            "srcSet": ["/static/4458ff7c995b9fe5167804dd1415684a/d2f63/limited_package.jpg 163w", "/static/4458ff7c995b9fe5167804dd1415684a/c989d/limited_package.jpg 325w", "/static/4458ff7c995b9fe5167804dd1415684a/6aca1/limited_package.jpg 650w", "/static/4458ff7c995b9fe5167804dd1415684a/7c09c/limited_package.jpg 975w", "/static/4458ff7c995b9fe5167804dd1415684a/01ab0/limited_package.jpg 1300w", "/static/4458ff7c995b9fe5167804dd1415684a/12609/limited_package.jpg 3000w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`🚛 `}<strong parentName="li">{`Moving to Discord`}</strong>{`:`}
          <ul parentName="li">
            <li parentName="ul">{`We decided to move to Discord to preserve all past messages.`}</li>
            <li parentName="ul">{`But no rush! We'll run the both Slack and Discord for a while. Please join `}<a parentName="li" {...{
                "href": "/s/discord"
              }}>{`our Discord server`}</a>{` whenever you're ready.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`🌟 `}<strong parentName="li">{`We're now 4K family members!`}</strong>
          <ul parentName="li">
            <li parentName="ul">{`It's amazing to see the steady growth of stars. Thank y'all!`}</li>
          </ul>
        </li>
      </ul>
      <a {...{
        "href": "https://github.com/line/armeria/stargazers"
      }}><img parentName="a" {...{
          "src": "/0829906cbe433e680cda7b3f4088fab9/star-history-20230420.svg",
          "width": 720
        }}></img></a>
    </div>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`✨ We've released several new versions since our last newsletter with a bunch of new exciting features! Here are the highlights between `}<a parentName="p" {...{
              "href": "/release-notes/1.17.1"
            }}>{`1.17.1`}</a>{` and `}<a parentName="p" {...{
              "href": "/release-notes/1.23.1"
            }}>{`1.23.1`}</a>{`.`}</p>
          <ul parentName="li">
            <li parentName="ul">
              <p parentName="li">{`Resilience4j circuit breaker support`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Improvements in gRPC support`}</p>
              <ul parentName="li">
                <li parentName="ul">{`Asynchronous ServerInterceptor + Kotlin Coroutine support`}</li>
                <li parentName="ul">{`Improved annotations support`}</li>
                <li parentName="ul">{`Improvements in gRPC HTTP/JSON transcoding`}</li>
              </ul>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Spring Boot 3 support (and dropping Spring Boot 1)`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Improvements in DocService`}</p>
              <ul parentName="li">
                <li parentName="ul">
                  <p parentName="li">{`Markdown and Mermaid support for DocService`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li">{`Autocompletion support for gRPC and Thrift`}</p>
                  <video className="hideOnReducedMotion" src="/b5033454c1ea3a0ae09eb2818fe73120/docservice-autocomplete.m4v" preload="none" autoPlay muted controls loop />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🎓 Try out this `}<a parentName="li" {...{
            "href": "/tutorials/grpc/blog"
          }}>{`guided tutorial for gRPC Service`}</a>{`! This super friendly and simple tutorial will guide you to make a blog service just like `}<a parentName="li" {...{
            "href": "/tutorials/rest/blog"
          }}>{`the previous tutorial for REST services`}</a>{`. But this time, you'll try step by step guide including:`}
          <ul parentName="li">
            <li parentName="ul">{`Implement Armeria gRPC service and client`}</li>
            <li parentName="ul">{`Exception handling`}</li>
            <li parentName="ul">{`Invoke gRPC service via DocService`}</li>
          </ul>
        </li>
        <li parentName="ul">{`🔋 Here are some cool open source projects powered by Armeria:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/discussions/4626#discussioncomment-4668166"
              }}>{`Thorium Framework`}</a>
              <ul parentName="li">
                <li parentName="ul">{`A Scala 3 microservice framework leveraging on Armeria`}</li>
              </ul>
            </li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/discussions/4626#discussioncomment-4863434"
              }}>{`Apache SkyWalking`}</a>
              <ul parentName="li">
                <li parentName="ul">{`An application performance monitor tool for distributed systems built on top of Armeria`}</li>
              </ul>
            </li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/naver/scavenger"
              }}>{`Scavenger`}</a>
              <ul parentName="li">
                <li parentName="ul">{`A runtime dead code analysis tool on top of Armeria`}</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🤔 Your opinion means a lot to us. Please let us know what you think about these proposals:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4314"
              }}>{`#4314`}</a>{` Provide an easier way to get a `}<a parentName="li" {...{
                "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
              }}>{`type://ClientRequestContext`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4569"
              }}>{`#4569`}</a>{` Consider RFC 7807 Problem Details for HTTP APIs as the default response format in REST APIs`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4738"
              }}>{`#4738`}</a>{` Consider a way to make `}<a parentName="li" {...{
                "href": "type://OAuth2AuthorizationGrant:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/auth/oauth2/OAuth2AuthorizationGrant.html"
              }}>{`type://OAuth2AuthorizationGrant`}</a>{` dynamic when update the grant information needed`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🔰 Want to contribute but not sure where to start from? Try this issue:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4797"
              }}>{`#4797`}</a>{` Contain which file doesn't exist in the response so that user get more clues to distinguish if the file service isn't bound at all or only the file doesn't exist`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['Bogyie', 'Bue-von-hon', 'DasAmpharos', 'Dogacel', 'Jimexist', 'KarboniteKream', 'Lincong', 'SeanWhoCodes', 'Yuri999', 'anuraaga', 'chanho0912', 'chris-ryan-square', 'chungonn', 'cj848', 'cnabro', 'cormoran', 'daniel-itunu', 'davin111', 'devdynam0507', 'di-seo', 'dinujoh', 'dlvenable', 'doljae', 'echo304', 'freevie', 'ghkim3221', 'heesuk-ahn', 'heowc', 'hirakida', 'hllee1021', 'hyperxpro', 'ikhoon', 'injae-kim', 'jrhee17', 'kezhenxu94', 'klurpicolo', 'kojilin', 'ks-yim', 'mauhiz', 'minwoox', 'mitrofmep', 'mscheong01', 'nao0811ta', 'natsumehu', 'ngyukman', 'opus53', 'policeman-kh', 'proceane', 'resquivel-squareup', 'rhodo', 'seonwoo960000', 'sullis', 'ta7uw', 'takezoe', 'taodo2291', 'tawAsh1', 'tobias-', 'tomatophobia', 'trustin', 'vkostyukov', 'wu-sheng', 'yamamichid']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      